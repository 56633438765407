@import (reference) "../../../../style/website.less";

.pricingPlanComparison {
    text-align: center;
    font-size: 10px;

    @media (max-width: @breakpoint-lg) {
        font-size: 14px;
    }

    :global {
        #pricing-plans {
            margin-bottom: 6em;
            display: flex;
            justify-content: center !important;
            & > .container {
                max-width: 831px;
            }

            .header-section {
                margin-top: 1.9rem;
                padding: 0 0 3.2rem;

                color: white;

                @media (max-width: @breakpoint-lg) {
                    margin-top: 0;
                    padding: 0 0 1.6rem;
                }

                .page-title {
                    h1 {
                        text-align: center;
                        .font-acumin-light;
                        font-size: 3.8rem;
                        font-style: normal;
                        line-height: normal;

                        @media (max-width: @breakpoint-lg) {
                            font-size: 2.6rem;
                            line-height: 1;
                        }
                    }
                }

                .page-sub-title {
                    margin-top: 0.3rem;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 1.45rem;
                    font-style: normal;
                    font-weight: 100;
                    line-height: normal;

                    @media (max-width: @breakpoint-lg) {
                        font-size: 1.43rem;
                        line-height: 2;
                    }
                }
            }

            .plans-section {
                margin-top: 0.5rem;
                @media (max-width: @breakpoint-lg) {
                    display: none;
                }
            }

            .plans-mobile {
                @media (min-width: @breakpoint-lg) {
                    display: none;
                }
            }
        }
    }
}
