@import (reference) "../../../../style/website.less";

.planSelector {
    font-size: 1.1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @coal;
    border-radius: 0.35em;
    padding: 2.5px;
    gap: 2.5px;

    @media (max-width: @breakpoint-lg) {
        margin-bottom: 2.35em;
    }

    .planOption {
        font-family: acumin-pro-condensed, sans-serif;
        font-size: 1.4em;
        flex: 1 1 auto;
        background-color: transparent;
        border: none;
        color: white;
        padding: 0.425em 0.85em 0.525em 0.85em;
        font-weight: 600;
        border-radius: 0.25em;
        cursor: pointer;
        transition: background-color 0.3s;

        &.selected {
            background-color: @screwdriver;
            color: white;
        }
    }
}
