.css-tooltip-mixin(@placement: "right"; @arrowSize: 0.3em; @borderSize: 0px; @color: rgba(0, 0, 0, 0.8)) {
    position: relative;
    @fontsize: 1em;
    @offset: 1em;

    .css-tooltip {
        font-size: @fontsize;
        box-sizing: border-box;
        opacity: 0;
        transition: opacity 0.125s linear;
        position: absolute;
        pointer-events: none;
        z-index: 999;
        cursor: default;
        width: 16.666em;

        & when (@placement = "right") {
            .positionReset();
            top: 50%;
            right: -@offset;
            transform: translate(100%, -50%);
        }

        & when (@placement = "bottom") {
            .positionReset();
            bottom: -@offset;
            left: 50%;
            transform: translate(-50%, 100%);
        }

        & when (@placement = "left") {
            .positionReset();
            top: 50%;
            left: -@offset;
            transform: translate(-100%, -50%);
        }

        & when (@placement = "top") {
            .positionReset();
            top: -@offset;
            left: 50%;
            transform: translate(-50%, -100%);
        }

        .css-tooltip-inner {
            width: 16.666em;
            font-style: normal;
            line-height: 1.57;
            font-weight: 300;
            color: white;
            text-align: center;
            background-color: @color;
            border-radius: 0.25em;
            padding: 0.5em 1em;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                display: block;
                width: 0px;
                border: @arrowSize solid transparent;

                & when (@placement = "right") {
                    .positionReset();
                    top: 50%;
                    left: 0;
                    border-left: 0;
                    border-right: @arrowSize solid @color;

                    & when (@borderSize = 0px) {
                        transform: translate(-100%, -50%);
                    }

                    & when not (@borderSize = 0px) {
                        transform: translate(calc(-100% - @borderSize), -50%);
                    }
                }

                & when (@placement = "bottom") {
                    .positionReset();
                    left: 50%;
                    top: 0;
                    border-top: 0;
                    border-bottom: @arrowSize solid @color;

                    & when (@borderSize = 0px) {
                        transform: translate(-50%, -100%);
                    }

                    & when not (@borderSize = 0px) {
                        transform: translate(-50%, calc(-100% - @borderSize));
                    }
                }

                & when (@placement = "left") {
                    .positionReset();
                    right: 0;
                    top: 50%;
                    border-right: 0;
                    border-left: @arrowSize solid @color;

                    & when (@borderSize = 0px) {
                        transform: translate(100%, -50%);
                    }

                    & when not (@borderSize = 0px) {
                        transform: translate(calc(100% + @borderSize), -50%);
                    }
                }

                & when (@placement = "top") {
                    .positionReset();
                    left: 50%;
                    bottom: 0;
                    border-bottom: 0;
                    border-top: @arrowSize solid @color;

                    & when (@borderSize = 0px) {
                        transform: translate(-50%, 100%);
                    }

                    & when not (@borderSize = 0px) {
                        transform: translate(-50%, calc(100% + @borderSize));
                    }
                }
            }
        }
        // Prevents the tooltip dissapearing when scrolling between the trigger element and the tooltip
        &:before {
            content: " ";
            position: absolute;
            opacity: 0;
            z-index: 999;

            & when (@placement = "right") {
                .positionReset();
                width: @offset * 1.2;
                height: 100%;
                top: 50%;
                left: 0;
                transform: translate(-@offset, -50%);
            }

            & when (@placement = "bottom") {
                .positionReset();
                width: 100%;
                height: @offset * 1.2;
                top: 0;
                left: 50%;
                transform: translate(-50%, -@offset);
            }

            & when (@placement = "left") {
                .positionReset();
                width: @offset * 1.2;
                height: 100%;
                top: 50%;
                right: 0;
                transform: translate(@offset, -50%);
            }

            & when (@placement = "top") {
                .positionReset();
                width: 100%;
                height: @offset * 1.2;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, @offset);
            }
        }
    }

    &:hover {
        .css-tooltip {
            opacity: 0.9;
            filter: alpha(opacity=90);
            transition-delay: 0.125s;
            pointer-events: all;
        }
    }
}

.positionReset() {
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
}
