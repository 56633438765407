@import (reference) "../../../../style/website.less";

.supportFeatures {
    font-size: 1em;

    @media (max-width: @breakpoint-lg) {
        font-size: 1.4em;
    }

    background: #01609b;
    padding: 4em 0 4.8em;

    @gutter: 1.4em;

    .content {
        margin: 0 auto;
        text-align: center;
        color: #fff;
        max-width: 831px;

        .headline {
            margin-bottom: 1.3em;
            font-weight: 700;
            line-height: 1.2;
            h2 {
                font-size: 2.7em;
            }
        }
    }

    .features {
        margin-top: 1.5em;
        color: @coal;

        .itemsContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            text-align: left;
            gap: @gutter;

            .item {
                flex: 0 1 calc(50% - (@gutter / 2));
                @media (max-width: @breakpoint-lg) {
                    flex: 0 1 auto;
                }
                padding: 1.2em 2.2em;
                background-color: white;
                border-radius: 0.5em;

                .title {
                    font-size: 1.5em;
                    font-weight: 300;
                    margin-bottom: 0.15em;
                    color: @water;
                    line-height: 1.1;
                }

                .description {
                    font-size: 0.81em;
                    line-height: 1.5;
                    color: #424242;
                }
            }
        }
    }
}
