@import (reference) "../../../../style/website.less";
@import "./CutCorner.mixin.less";
@import "./ToolTip.mixin.less";

.plan {
    @tier1-color: @darkspark;
    @tier2-color: @water;
    @tier3-color: @lawn;
    background-color: #fff;
    position: relative;
    overflow: visible;
    color: @coal;
    margin-bottom: 2.1428em;

    &.mostPopular {
        :global {
            .plan-most-popular {
                @offset: 10em;

                width: @offset;
                height: @offset;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                overflow: hidden;

                .ribbon {
                    padding: 2.6em 0 0;
                    height: 5.7em;
                    background: #263238;
                    position: absolute;
                    width: 200%;
                    transform: translate(-8%, -18.6%) rotate(45deg);
                }

                .text {
                    color: #fff;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 1.2em;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1em;
                    padding: 0 6em;
                }
            }
        }
    }

    &:global {
        &.plan-12 .plan-header .content {
            background: @tier1-color;
        }

        &.plan-13 .plan-header .content {
            background: @tier2-color;
        }

        &.plan-14 .plan-header .content {
            background: @tier3-color;
        }
    }

    :global {
        .plan-header {
            color: white;
            overflow: hidden;

            .content {
                padding: 2.15em 1em 1.6em;
                margin: 0.9em 1em 2em 1em;
                height: 20.8em;
                .cut-corner(2.5em);

                @media (max-width: @breakpoint-lg) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: auto;
                    padding: 2.6em 1em 3.2em;
                }

                .plan-title {
                    color: #fff;
                    text-align: center;
                    font-family: "acumin-pro-condensed", Roboto, sans-serif;
                    font-size: 3.4em;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    @media (max-width: @breakpoint-lg) {
                        font-size: 2.9em;
                    }
                }

                .plan-short-description {
                    margin-top: -0.25em;
                    color: #fff;
                    text-align: center;
                    font-family: "acumin-pro-condensed", Roboto, sans-serif;
                    font-size: 1.8em;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.2;
                    letter-spacing: 0.01em;

                    @media (max-width: @breakpoint-lg) {
                        line-height: 1.1;
                        font-size: 1.7em;
                        max-width: 9.2em;
                        margin-top: -0.25em;
                    }
                }

                .plan-select {
                    margin-top: 1.75em;

                    .btn {
                        &:hover:not(.current-plan) {
                            background: #39464c;
                        }
                    }
                }

                .price {
                    margin-top: 1em;
                    margin-bottom: 0.2em;
                    font-family: acumin-pro-condensed, sans-serif;
                    font-weight: 700;

                    .price-text {
                        font-size: 3.2em;
                        line-height: 1;
                        display: flex;
                        justify-content: center;

                        @media (max-width: @breakpoint-lg) {
                            margin-top: 0.25em;
                            font-size: 2.8em;
                        }

                        .price-symbol {
                            font-size: 0.5em;
                            margin-right: 0.05em;
                            top: 0.8em;
                            line-height: 1.7;
                        }

                        .price-number {
                            display: inline-block;
                        }
                    }
                }

                .price-info {
                    font-family: acumin-pro-condensed, sans-serif;
                    font-weight: 400;
                    font-size: 1.45em;
                    clear: both;
                    display: block;
                    width: 100%;
                    display: inline;
                    .tax-info {
                        display: inline;
                        margin-left: 0.25em;
                    }

                    @media (max-width: @breakpoint-lg) {
                        font-size: 1.38em;
                    }
                }
            }
        }

        .plan-body {
            color: #424242;
            text-align: center;
            font-family: Roboto;
            font-size: 1.2em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            & > div {
                padding: 0 0.833333333em;
            }

            ul {
                list-style: none;
                list-style-type: none;
                padding: 0;

                li.plan-feature,
                li.plan-addon {
                    margin-bottom: 0.61em;
                    cursor: default;
                    position: relative;

                    .feature-name,
                    .addon-name {
                        position: relative;
                        cursor: default;
                        display: inline-block;
                    }

                    &.show-tooltip {
                        .css-tooltip-mixin("right");
                        @media screen and (max-width: 1400px) {
                            .css-tooltip-mixin("top");
                        }

                        .feature-name,
                        .addon-name {
                            text-decoration: underline;
                            text-underline-offset: 0.18em;
                            text-decoration-color: #d6dadd;
                        }
                    }

                    &.separator {
                        border-bottom: 1px solid #e0e0e0;
                        margin: 1.8em 1em 1.5em 1em;
                    }
                }
            }

            .plan-addons {
                margin: 1.7em 0 0;
                padding: 1.5em 0 0.4em;
                background: #f3f3f3;
                border-top: 1px solid #e3e6ea;

                p {
                    margin-bottom: 0.6em;

                    strong {
                        font-weight: 500;
                        margin-bottom: 1em;
                        display: block;
                    }
                }

                ul {
                    margin-top: -0.45em;
                    margin-bottom: 1.3em;
                }
            }
        }
    }
}
