@import (reference) "../../../style/website.less";

.button {
    .font-acumin;
    font-weight: 700;
    padding: .25em 1.25em .35em;
    color: @tradify-orange;
    border: 2px solid @tradify-orange;
    border-radius: .25em;
    transition: .2s ease-out;
    background: none;

    &:hover {
        color: white;
        background: @tradify-orange;
    }

    &.primary {
        color: white;
        background: @tradify-orange;

        &:hover {
            color: white;
            background: darken(@tradify-orange, 3%);
            border: 2px solid fade(white, 20%);
        }
    }
}