.planCta {
    width: 100%;
    padding: 0 0.8333333em;
    a,
    button {
        width: 100%;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 1.2em;
        padding: 0.7em;
        padding-bottom: 0.6em;
        margin-bottom: 1em;
    }
}
