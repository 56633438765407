@import (reference) "../../../style/website.less";

.pageContentItem {
    padding: 0.75em 0;
    position: relative;
    display: block;

    & > * {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media (min-width: 576px) {
            max-width: 540px;
        }

        @media (min-width: 768px) {
            max-width: 720px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1140px;
        }
    }
}

.paperBackground {
    background: @paper;
    color: @coal;

    &.hasBackgroundImage:after {
        background-color: fade(@paper, 10%);
    }
}

.coalBackground {
    background: @coal;
    color: white;

    &.hasBackgroundImage:after {
        background-color: fade(@coal, 10%);
    }
}

.blueBackground {
    background: @darkish-blue;
    color: white;

    &.hasBackgroundImage:after {
        background-color: fade(@darkish-blue, 80%);
    }
}

.whiteBackground {
    background: @white;
    color: @coal;

    &.hasBackgroundImage:after {
        background-color: fade(@white, 10%);
    }
}

.tradifyBlueBackground {
    background: @water;
    color: white;

    &.hasBackgroundImage::after {
        background: linear-gradient(to left, rgba(0, 158, 199, 0.8), rgba(0, 75, 140, 0.8));
    }
}

.lightGreyBackground {
    background: linear-gradient(277.46deg, #ffffff -35.84%, #e5e5e5 74.2%);
    color: white;

    &.hasBackgroundImage:after {
        background: linear-gradient(277.46deg, #ffffff -35.84%, #e5e5e5 74.2%);
    }
}

.hasBackgroundImage {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media (max-width: 1000px) {
        background-position-x: -20vw;
    }

    @media (max-width: 800px) {
        background-position-x: -80vw;
    }

    @media (max-width: 600px) {
        background-position-x: -100vw;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .container {
        position: relative;
        z-index: 1;
    }
}
