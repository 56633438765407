@import (reference) "../../../../style/website.less";

.signupCta {
    font-size: 1em;
    background: radial-gradient(ellipse at top left, #009cc7, #004b8c),
        radial-gradient(ellipse at bottom right, #009cc7, transparent);
    background-blend-mode: multiply;

    padding: 4.7em 0;

    .content {
        margin: 0 auto;
        text-align: center;
        color: #fff;

        .headline {
            margin-bottom: 1.3em;
            font-weight: 700;
            line-height: 1.2;

            h2 {
                font-size: 4.8em;
                font-weight: 800;
            }

            p {
                margin-top: 1.3em;
                font-size: 1.9em;
                font-weight: 400;
                a {
                    color: white;
                }
            }
        }
    }

    .signupCtaButton {
        margin-top: 2.3em;
        font-size: 1.26em;
        & a,
        & button {
            font-weight: 600;
        }
    }
}
